import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout2"
import Post from "../components/Post"
import PaginationLinks from "../components/PaginationLinks"

const PostListCronicas = props => {
  const posts = props.data.allMarkdownRemark.edges
  const { currentPage, numberOfCronicasPages } = props.pageContext
  return (
    <Layout title={`Crônicas & Ensaios - Página: ${currentPage}`}>
      {posts.map(({ node }) => (
        <Post
          key={node.id}
          slug={`cronicas/${node.fields.slug}`}
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          excerpt={node.excerpt}
          tags={node.frontmatter.tags}
          fluid={node.frontmatter.image.childImageSharp.fluid}
        />
      ))}
      <PaginationLinks
        currentPage={currentPage}
        numberOfPages={numberOfCronicasPages}
        pathPrefix="cronicas"
      />
    </Layout>
  )
}

export const postListCronicasQuery = graphql`
  query postListCronicasQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "cronicas" } } }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD/MM/YYYY")
            category
            tags
            image {
              childImageSharp {
                fluid(maxWidth: 650) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`

export default PostListCronicas
